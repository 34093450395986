import React from "react"
import GeneralPest from "../../home/generalPest"
import { RichText } from "prismic-reactjs"
import stone from "../../../assets/layout/stone.svg"
import Video from "../../home/whoAreWe/video"
import "./style.scss"

export default function commercialPest({ data, offerIncludes, offerFeatures }) {
  const formattedUrl = data.primary
    ? data.primary.youtube_embed_link
      ? data.primary.youtube_embed_link.embed_url
        ? data.primary.youtube_embed_link.embed_url.replace(
            "watch?v=",
            "embed/"
          )
        : null
      : null
    : null
  const videoCode = data.primary
    ? data.primary.youtube_embed_link
      ? data.primary.youtube_embed_link.embed_url
        ? data.primary.youtube_embed_link.embed_url.split("?v=")[1]
        : null
      : null
    : null

  return (
    <div className="info-commercialPest">
      <div className="container">
        <div className="info-general-pest">
          <GeneralPest
            offerIncludes={offerIncludes}
            offerFeatures={offerFeatures}
          />
        </div>
        <div className="info-commercialPest-content">
          <RichText render={data.primary.title} />
          <img src={stone} alt="" className="info-commercialPest-stone" />
          <RichText render={data.primary.description} />
        </div>
        <Video
          videoSrcURL={
            formattedUrl !== null
              ? formattedUrl
              : "https://www.youtube.com/embed/6OM3zoAJ3lU"
          }
          videoCode={videoCode !== null ? videoCode : "6OM3zoAJ3lU"}
          videoTitle={
            data.primary
              ? data.primary.youtube_embed_link
                ? data.primary.youtube_embed_link.title
                  ? data.primary.youtube_embed_link.title
                  : ""
                : ""
              : ""
          }
        />
      </div>
    </div>
  )
}
