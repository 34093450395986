import React, { useEffect, useState } from "react"
import GeneralPest from "../../home/generalPest"
import ratings from "../../../assets/home/rating.png"
import BannerCarousel from "../../home/hero/BannerCarousel"
import "./style.scss"

export default function Hero({
  data,
  bannerImage1,
  bannerImage2,
  offerIncludes,
  offerFeatures,
}) {
  const [screenWidth, setScreenWidth] = useState(2000)

  useEffect(() => {
    if (window !== "undefined") {
      setScreenWidth(window.innerWidth)
    }
  }, [])

  useEffect(() => {
    if (window !== "undefined") {
      window.addEventListener("resize", function () {
        setScreenWidth(this.window.innerWidth)
      })
    }
  })
  return (
    <div
      className="termite-hero"
      style={
        screenWidth >= 1200
          ? bannerImage1 === null && bannerImage2 === null
            ? {
                backgroundImage: `url(${data.src ? data.src.url : null})`,
                backgroundSize: "80%",
                backgroundPosition: "left center",
              }
            : bannerImage1 !== null && bannerImage2 === null
            ? {
                backgroundImage: `url(${
                  bannerImage1 ? bannerImage1.url : null
                })`,
                backgroundSize: "80%",
                backgroundPosition: "left center",
              }
            : {}
          : {}
      }
    >
      {screenWidth < 1200 ? (
        <img
          style={{ maxWidth: "150%" }}
          src={
            bannerImage1 === null && bannerImage2 === null
              ? data.src
              : bannerImage1 !== null && bannerImage2 === null
              ? bannerImage1.url
              : null
          }
        />
      ) : null}
      {/* <div className="termite-hero-container">
        <div className="termite-general">
           <GeneralPest />
         </div>
         <div className="termite-google-ratings">
         <img src={ratings} alt="google-ratings" />
         </div>
         <div></div>
       </div>  */}
      {screenWidth < 1200 ? null : (
        <div className="home-hero-container">
          {bannerImage1 !== null && bannerImage2 !== null ? (
            <BannerCarousel
              bannerImage1={bannerImage1}
              bannerImage2={bannerImage2}
            />
          ) : null}
          <div className="banner-absolute">
            <div className="general">
              <GeneralPest
                offerIncludes={offerIncludes}
                offerFeatures={offerFeatures}
              />
            </div>
            <div className="google-ratings">
              <img src={ratings} alt="google-ratings" />
            </div>
          </div>
        </div>
      )}

      <div
        className={"bottom-svg"}
        style={{ textAlign: "center", transform: "translateY(-25%)" }}
      >
        {screenWidth < 1200 ? (
          <img
            className="bottom-svg-rating"
            style={{
              maxWidth: "300px",
            }}
            src={ratings}
            alt="google-ratings"
          />
        ) : null}
        <img
          className={"bottom-svg-img"}
          style={{ width: "100%" }}
          src={
            "https://pestcontrol.cdn.prismic.io/pestcontrol/5eaf5c84-0209-40fc-bfda-27c7d71f77c9_bottomSvg.svg"
          }
        />
      </div>
    </div>
  )
}
